<template>
  <!-- 采购退货单 -->
  <div>
    <div
      class="view-wrap"
      v-if="!settingVisible && !ExamineVisible && !EditVisible"
    >
      <div class="search">
        <el-form :inline="true" :model="searcForm">
          <el-form-item
            prop="purchaseRefundNo"
            :rules="[
              {
                min: 0,
                max: 32,
                message: '长度在 0 到 32 个字符',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              style="width: 100%"
              v-model="searcForm.purchaseRefundNo"
              placeholder="输入单据号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="param"
            :rules="[
              {
                min: 0,
                max: 32,
                message: '长度在 0 到 32 个字符',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              style="width: 100%"
              v-model="searcForm.param"
              placeholder="输入供应商名称/编号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="录单日期">
            <el-date-picker
              v-model="dateTime"
              type="daterange"
              range-separator="至"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              :picker-options="pickerOptions"
              end-placeholder="结束日期"
              :clearable="false"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="退款状态">
            <el-select
              v-model="searcForm.payStatus"
              placeholder="全部"
              clearable
              style="width: 160px"
            >
              <el-option
                v-for="item in payStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="单据状态">
            <el-select
              v-model="searcForm.status"
              placeholder="全部"
              clearable
              style="width: 160px"
            >
              <el-option
                v-for="item in StatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData"
              ><i class="el-icon-search" /> 查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="button">
        <el-button type="primary" round @click="AddPustorage">
          <i class="el-icon-plus" /> 新增</el-button
        >
      </div>
      <div v-loading="loading" class="view">
        <SimpleTable
          :data="tableData"
          :tableFields="tableItem"
          :operation="[]"
          :currentPage="searcForm.pageNum"
          :pageSize="searcForm.pageSize"
          :total="total"
          @sizeChange="changePageSize"
          @currentChange="changeCurrentPage"
        >
          <div slot="supplierColumn" slot-scope="row">
            <span>{{
              `${row.data.name}(${row.data.code})`
            }}</span>
          </div>
          <div slot="StatusColumn" slot-scope="row">
            <div
              :style="
                Number(row.data.payStatus) === 2 ? { color: '#409EFF' } : {}
              "
            >
              {{
                !Number(row.data.payStatus)
                  ? "未退款"
                  : Number(row.data.payStatus) === 2
                  ? "全部退款"
                  : "部分退款"
              }}
            </div>
          </div>
          <div slot="Column" slot-scope="row">
            <span>{{ Number(row.data.status) ? "草稿" : "已完成" }}</span>
          </div>
          <div slot="operation" style="text-align: center" slot-scope="row">
            <el-tooltip
              class="item"
              effect="dark"
              content="查看"
              placement="top-start"
            >
              <el-button type="text" @click="examine(row.data)"
                ><i class="el-icon-view"></i
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="修改"
              placement="top-start"
            >
              <el-button
                type="text"
                :disabled="!Number(row.data.status)"
                @click="EditPustorage(row.data)"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top-start"
            >
              <el-button type="text" @click="deleteData(row.data)"
                ><i class="el-icon-delete"></i
              ></el-button>
            </el-tooltip>
          </div>
        </SimpleTable>
      </div>
    </div>
    <AddTable
      v-if="settingVisible"
      :isEdit="isEdit"
      :data="ExamineData"
      :total="total"
      @back="closeView"
      @refresh="refreshList"
    />
    <ExamineInfo
      v-if="ExamineVisible"
      :data="ExamineData"
      :total="total"
      @back="closeView"
      @refresh="refreshList"
    />
    <!-- <EditTable  v-if="EditVisible" @back="closeView" @refresh="refreshList" /> -->
    <OpenAccount :info="info"></OpenAccount>
  </div>
</template>
<script>
import { GetPurchaseRefundList, deletePurchase } from "@/api/purchaseRefund.js";
import { datatimestatus } from "@/until/auth";
import AddTable from "./AddSalesreturn.vue";
import ExamineInfo from "./ExamineSalesreturn.vue";
import { getConfigBill } from "@/api/user";
import OpenAccount from "@/views/common/OpenAccount";
export default {
  components: {
    AddTable,
    ExamineInfo,
    OpenAccount,
  },
  data() {
    return {
      openAccountFlag:true,
      info: {
        show: false,
      },
      settingVisible: false, // 新增
      ExamineVisible: false, // 查看
      EditVisible: false, // 修改
      searcForm: {
        pageNum: 1,
        pageSize: 10,
        purchaseRefundNo: "",
        param: "",
        startDate: "",
        endDate: "",
        payStatus: "",
        status: "",
      },
      payStatusList: [
        { label: "未退款", value: 0 },
        { label: "部分退款", value: 1 },
        { label: "全部退款", value: 2 },
      ],
      StatusList: [
        { label: "已完成", value: 0 },
        { label: "草稿", value: 1 },
      ],
      dateTime: [],
      pickerOptions: null,
      loading: false,
      tableData: [],
      tableItem: [
        { prop: "entryDate", label: "录单日期", type: "default" },
        { prop: "purchaseRefundNo", label: "单据号", type: "default" },
        { prop: "supplierName", label: "供应商", type: "supplierslot" },
        { prop: "purchaseNo", label: "关联入库单据号", type: "default" },
        { prop: "handlePrice", label: "应退金额", type: "price" },
        { prop: "prepaidPrice", label: "已退金额", type: "price" },
        { prop: "payStatus", label: "退款状态", type: "payStatuslot" },
        { prop: "status", label: "单据状态", type: "statusSlot" },
        { prop: "createUserName", label: "制单人", type: "default" },
        { label: "操作", type: "operation" },
      ],
      total: 0,
      // 当前查看的数据
      ExamineData: null,
      // 是否修改
      isEdit: false,
    };
  },
  watch: {
    dateTime(val) {
      if (!val) {
        this.searcForm.startDate = "";
        this.searcForm.endDate = "";
      } else {
        this.searcForm.startDate = val[0];
        this.searcForm.endDate = val[1];
      }
    },
  },
  created() {
    this.getConfigBills();
    this.datatimestatus();
    this.getDate();
  },
  methods: {
    async getConfigBills() {
      let res = await getConfigBill();
      if (res.code == 1) {
        if (res.data.isBill == 0) {
          this.info.show = true;
          this.openAccountFlag = false;
        }
      }
    },
    datatimestatus() {
      this.pickerOptions = datatimestatus();
    },
    // 默认时间
     getDate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month =
        now.getMonth() + 1 >= 10
          ? now.getMonth() + 1
          : "0" + (now.getMonth() + 1); //得到月份
      var date = now.getDate() >= 10 ? now.getDate() : "0" + now.getDate(); //得到日期
      if (this.openAccountFlag) {
        var reg = sessionStorage.getItem("createDate").split(" ")[0];
        var defaultDate = [`${reg}`, `${year}-${month}-${date}`];
        this.searcForm.startDate = `${reg}`;
        this.searcForm.endDate = `${year}-${month}-${date}`;
      }else{
         var defaultDate = [`${year}-${month}-01`, `${year}-${month}-${date}`];
        this.searcForm.startDate = `${year}-${month}-01`;
        this.searcForm.endDate = `${year}-${month}-${date}`;
      }
      this.dateTime = defaultDate;
      this.getData();
    },
    getData() {
      this.loading = true;
      GetPurchaseRefundList(this.searcForm).then((res) => {
        if (res.code === 1) {
          const { records, total, current } = res.data;
          this.tableData = records;
          this.total = total;
          this.searcForm.pageNum = current;
          // 当没有数据
          if (records.length === 0) {
            // 判断是否为第一页
            if (current === 1) {
              this.searcForm.pageNum = 1;
              this.loading = false;
              return;
            } else {
              // 不是回退再重新搜索
              this.searcForm.pageNum = current - 1;
              this.getData();
            }
          }
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    // 新增
    AddPustorage() {
      this.ExamineData = {};
      this.isEdit = false;
      this.settingVisible = true;
    },
    // 查看
    examine(data) {
      this.ExamineData = data;
      this.ExamineVisible = true;
    },
    // 修改
    EditPustorage(data) {
      this.ExamineData = data;
      this.isEdit = true;
      this.settingVisible = true;
    },
    // 分页
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getData();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      this.getData();
    },
    // 返回列表
    closeView() {
      this.ExamineData = {};
      this.settingVisible = false;
      this.ExamineVisible = false;
      this.EditVisible = false;
    },
    // 刷新
    refreshList() {
      this.closeView();
      this.getData();
    },
    deleteData(data) {
      this.$confirm(`是否删除该单据？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        deletePurchase(data.id).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
          this.refreshList();
        });
      });
    },
  },
};
</script>